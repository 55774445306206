<template>
  <keep-alive>
    <div class="video-slide">
      <div
        id="player"
        data-plyr-provider="vimeo"
        data-plyr-embed-id="757894953"
      ></div>
    </div>
  </keep-alive>
</template>
<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";

export default {
  data() {
    return {
      player: null,
    };
  },
  methods: {
    loadPlayer() {
      this.player = new Plyr("#player", {
        controls: ["play-large"],
        fullscreen: { enabled: false },
      });
      window.player = this.player;

      let audioBox = document.getElementById("audio");

      this.player.on("playing", () => {
        audioBox.pause();
      });
      audioBox.addEventListener("playing", () => {
        this.player.pause();
      });
      const videoWrapper = document.getElementsByClassName(
        "plyr__video-wrapper"
      )[0];
      videoWrapper.addEventListener("click", (event) => {
        this.player.togglePlay();
        event.stopPropagation(); // Necessary or the video will toggle twice => no playback
      });

      this.player.toggleControls(false);
    },
  },
  mounted() {
  
      this.loadPlayer();
    
  },
  unmounted() {
   
      this.player.destroy();
    
  },
};
</script>
<style  lang="scss">
.youtube-video-slide {
  width: 100%;
  background: #ddcfb2;
}

iframe {
  width: 100vw;
  height: calc(100vw / 1.77);
}
.video-slide .plyr__control svg {
  width: 70px !important;
  height: 70px;
}
.play-button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 8rem;
  color: white;
  filter: invert(100%);
}
.video-slide {
  width: 100%;
  background: #ddcfb2;
  position: relative;
}

// .plyr__video-embed,
// .plyr__video-wrapper--fixed-ratio {
//   padding-bottom: 50% !important;
// }
video {
  width: 100vw;
  height: auto;
}
video:hover {
  cursor: pointer;
}
</style>