import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { h, resolveComponent } from "vue";
import i18n from "@/i18n";

const routes = [
  {
    path: "/",
    redirect: `/${i18n.global.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      

      {
        path: 'programme',
        name: 'programme',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ProgrammeView.vue')
      }
    ],
  },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,  scrollBehavior() {
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language === undefined) {
    language = "it";
  }

  i18n.global.locale = language;

next();
});

export default router;

