<template>
  <div class="about-text d-flex justify-content-center">
    <div
      class="
        py-5
        my-3
        container
        about-wrapper
        d-flex
        justify-content-center
        align-items-center
        row
      "
    >
      <div v-html="slideText" class="col-md-9 col-12 px-0 pt-2"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["slideText"],
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
a{
  color:white;
}
p {
  margin-bottom: 0;
}
.title {
  font-size: 1.6rem;
  text-align: center;
  text-transform: uppercase;
}
.about-text {
  min-height: auto;

  background: #ddcfb2;
}
.about-wrapper {
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
  height: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 1.3rem;
}

@media (min-width: 768px) {
  .about-text {
    min-height: 100%;
  }
  .about-wrapper {
    font-size: 1.4rem;
    text-align: center;
    line-height: 2rem;
  }
}
</style>