<template>
  <nav id="nav">
    <!-- Menu top bar -->
    <div
      :class="[
        !onProgramme || (onProgramme && menuOpen)
          ? 'home-top-background'
          : 'programme-top-background',
      ]"
      class="menu-top-bar pb-2"
    >
      <div class="container d-flex justify-content-between mt-3 pb-3 px-md-0">
        <div class="menu-button ms-md-2 ms-0">
          <div class="row flex-nowrap">
            <div @click="menuOpen = !menuOpen" class="col-auto pe-0">
              <span v-if="!menuOpen">Menu</span
              ><span v-else class="faded-text">
                {{ $t("furniture.close") }}</span
              >
            </div>
            <div v-if="!menuOpen" class="col-auto p-0 d-none d-md-block">
             <div class="ms-3 col-auto p-0 lang-switch-desktop">
                  <span @click.prevent="setLocale('it')">IT</span> -
                  <span @click.prevent="setLocale('en')">EN</span>
                </div>
            </div>
           
          </div>
        </div>
        <div v-if="!menuOpen" class="page-title d-none d-md-block">
          {{ pageTitle }}
        </div>
        <div class="status">
          <span class="me-2">ON AIR</span>

          <div class="red-dot me-2"></div>
          <span>
            <span
              v-if="!audioPlaying"
              @click="playAudio"
              class="me-0 me-md-2 player-button"
              >Play</span
            >
            <span v-if="audioPlaying" @click="pauseAudio" class="player-button"
              >Pause</span
            >
          </span>
        </div>
      </div>
    </div>
    <!-- Menu dropdown -->
    <transition name="dropdown">
      <div
        v-if="menuOpen"
        :class="[onProgramme ? 'programme-background' : 'home-background']"
        class="menu-dropdown"
      >
        <div class="container mt-5 pt-4">
          <div class="row">
            <router-link class="col-12 ps-2" to="/">Home</router-link>

            <router-link class="col-12 ps-2" :to="`/${$i18n.locale}/programme`">{{
              $t("furniture.programme")
            }}</router-link>

            <div @click="scrollToInstallationView" class="col-12 ps-2 link">
              Installation View
            </div>

            <div @click="scrollToNewsletter" class="col-12 ps-2 link">
              Newsletter
            </div>

            <div @click="scrollToInfo" class="col-12 ps-2 link">
              Info &#38; Visit
            </div>

            <div class="lang-switch d-block d-md-none ps-2">
              <div
                @click.prevent="setLocale('it')"
                :class="[$i18n.locale === 'it' ? '' : 'faded-text']"
                class="col-12"
              >
                ITALIANO
              </div>
              <div
                @click.prevent="setLocale('en')"
                :class="[$i18n.locale === 'en' ? '' : 'faded-text']"
                class="col-12"
              >
                ENGLISH
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="menuOpen" class="background-blur"></div
    ></transition>
    <audio loop id="audio" preload="auto">
      <source
        src="https://d3kc4mzo6omdrt.cloudfront.net/a-storm-audio.mp3"
        type="audio/mpeg"
      />

      <source
        src="https://d3kc4mzo6omdrt.cloudfront.net/a-storm-audio.ogg"
        type="audio/ogg"
      />

      <a href="audiofile.mp3">download audio</a>
    </audio>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showLang: false,
      menuOpen: false,
      pageTitle: "A STORM IS BLOWING FROM PARADISE",
      onProgramme: false,
      audioPlaying: false,
    };
  },
  mounted() {
    this.setTitle();
    let prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (!this.menuOpen) {
        if (prevScrollpos > currentScrollPos || window.scrollY == 0) {
          document.getElementById("nav").style.top = "0";
          document.getElementsByClassName("menu-top-bar")[0].style.background =
            this.menuBarColour;
        } else {
          document.getElementById("nav").style.top = "-6rem";
        }

        prevScrollpos = currentScrollPos;

        if (currentScrollPos <= 0 || window.scrollY == 0) {
          document.getElementsByClassName("menu-top-bar")[0].style.background =
            "rgba(0, 0, 0, 0)";
        }
      } else return;
    };
    document.getElementById("audio").addEventListener("pause", () => {
      this.audioPlaying = false;
    });
  },
  computed: {
    menuBarColour() {
      if (this.onProgramme) {
        return "linear-gradient(180deg,rgba(221, 207, 178, 1) 0%,rgba(221, 207, 178, 0.9) 30%,rgba(221, 207, 178, 0.75) 50%,rgba(221, 207, 178, 0.5) 70%,rgba(221, 207, 178, 0) 100%)";
      } else
        return "linear-gradient(180deg,rgba(5, 70, 113, 1) 0%,rgba(5, 70, 113, 0.9) 30%,rgba(5, 70, 113, 0.75) 50%,rgba(5, 70, 113, 0.5) 70%,rgba(5, 70, 113, 0) 100%)";
    },
  },
  methods: {
    setTitle() {
      if (this.$route.name === "programme") {
        this.onProgramme = true;
        this.pageTitle = this.$t("furniture.public");
      } else {
        this.onProgramme = false;
        this.pageTitle = "A STORM IS BLOWING FROM PARADISE";
      }
    },
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("language", locale);
        this.$i18n.locale = localStorage.getItem("language");
        this.$router.push({
          params: {
            lang: locale,
          },
        });
      }
      this.showLang = false;
      this.menuOpen = false;
    },
    scrollToInstallationView() {
      this.menuOpen = false;
      this.$router.push("/" + this.$i18n.locale);
      setTimeout(() => {
        let height = document.getElementById("installation-gallery").offsetTop;

        window.scrollTo(0, height);
      }, 300);
    },
    scrollToNewsletter() {
      this.menuOpen = false;
      let height = document.getElementById("newsletter").offsetTop;

      window.scrollTo(0, height);
    },
    scrollToInfo() {
      this.menuOpen = false;
      let height = document.getElementById("footer").offsetTop;

      window.scrollTo(0, height);
    },
    playAudio() {
      let audioBox = document.getElementById("audio");

      audioBox.play();
      this.audioPlaying = true;
      let video = document.getElementById("drone-video");

      if (video) {
        video.pause();
      }
    },
    pauseAudio() {
      let audioBox = document.getElementById("audio");
      audioBox.pause();
      this.audioPlaying = false;
    },
  },

  watch: {
    $route() {
      this.setTitle();
      this.menuOpen = false;
    },

    menuOpen() {
      if (this.menuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#audio {
  height: 0;
  width: 0;
}
.player-button:hover {
  cursor: pointer;
}
.background-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 1;
  opacity: 1;
  transition: opacity 200ms ease;
}
.red-dot {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background: #a22020;
  display: inline-block;
}
div {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.home-background {
  background: #054671;
  color: white;
}
.home-background a,
.home-top-background a {
  color: white;
}

.programme-background {
  color: #054671;
  background: #ddcfb2;
}
.programme-background a,
.programme-top-background a {
  color: #054671;
}
.home-top-background {
  background: linear-gradient(
    180deg,
    rgba(5, 70, 113, 1) 0%,
    rgba(5, 70, 113, 0.9) 30%,
    rgba(5, 70, 113, 0.75) 50%,
    rgba(5, 70, 113, 0.5) 70%,
    rgba(5, 70, 113, 0) 100%
  );
}
.programme-top-background {
  background: linear-gradient(
    180deg,
    rgba(221, 207, 178, 1) 0%,
    rgba(221, 207, 178, 0.9) 30%,
    rgba(221, 207, 178, 0.75) 50%,
    rgba(221, 207, 178, 0.5) 70%,
    rgba(221, 207, 178, 0) 100%
  );
}

.dropdown-inner-wrapper {
  height: 100%;
  position: relative;
}
.large-links {
  position: absolute;
  top: 0;
}
.lang-switch {
  z-index: 10;
  position: fixed;
  bottom: 1rem;
  font-size: 24px;
  font-family: "Monument Grotesk", Helvetica, Arial, sans-serif;
}
.lang-switch:hover {
  cursor: pointer;
}
.lang-switch-desktop {
  line-height: 10px;
    font-family: "Monument Grotesk Mono", Helvetica, Arial, sans-serif;
}

.menu-dropdown {
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  height: 100vh;
  top: 0;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
}
.faded-text {
  color: #5486a7;
}

@media (min-width: 768px) {
  .menu-button,
  .status {
    min-width: 12rem !important;
    max-width: 12rem !important;
    width: 12rem !important;
  }
  .faded-text {
    color: white;
  }
  .menu-dropdown {
    width: 100%;
    position: absolute;
    height: 18rem;
    top: 0;
    font-family: "GT Super Text", "Times New Roman", Times, serif;
  }
}

.menu-dropdown a,
.menu-dropdown .link {
  text-decoration: none;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.link:hover {
  cursor: pointer;
}

.menu-top-bar {
  position: absolute;
  width: 100%;
  z-index: 3;
  background: transparent;
  transition: all 300ms ease;
}

.lang-wrapper {
  overflow: hidden;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition: all 150ms ease-out;
}
.fade-leave-active {
  transition: all 150ms ease-in;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.dropdown-enter-from,
.dropdown-leave-to {
  transform: translateY(-10rem);
  opacity: 0;
}
.dropdown-enter-active {
  transition: all 150ms ease-out;
}
.dropdown-leave-active {
  transition: all 150ms ease-in;
}
.dropdown-enter-to,
.dropdown-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.lang-enter-from,
.lang-leave-to {
  transform: translateX(-3rem);
}
.lang-enter-active {
  transition: all 200ms ease-out;
}
.lang-leave-active {
  transition: all 200ms ease-in;
}
.lang-enter-to,
.lang-leave-from {
  transform: translateX(0);
}

.menu-button,
.status {
  min-width: auto;
  max-width: auto;
  width: auto;
}
.menu-button:hover {
  cursor: pointer;
}
.status {
  text-align: right;
}
.page-title {
  font-family: "GT Super Text", "Times New Roman", Times, serif;
  text-align: center;
}
.world-icon {
  width: 1.5rem;
}
nav {
  z-index: 2;
  transition: 500ms ease;
  position: fixed;
  top: 0;
  width: 100%;
  height: 6rem;
}
</style>