<template>
  <div id="newsletter" class="newsletter">
    <div class="newsletter-wrapper d-flex align-items-stretch container">
      <div class="row d-flex align-content-between justify-content-center">
        <!-- Title -->
        <div class="col-12 mt-5 title">
          newsletter
          <br /><span>sign up</span>
        </div>
        <!-- Form -->
        <div class="col-12 col-md-8 my-4 my-md-0">
          <form
            v-show="!formSent"
            action="https://stormfromparadise.us14.list-manage.com/subscribe/post?u=3738a6018b210b17367403c18&amp;id=e5cb3d003e&amp;f_id=0003f2e0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate row"
            target="_blank"
            novalidate
          >
            <div class="col-12 col-md-6">
              <input
                type="text"
                value=""
                name="FNAME"
                :placeholder="$t('furniture.name')"
                class="form-control col-6"
                id="mce-FNAME"
              />
            </div>
            <div class="col-12 col-md-6 my-4 my-md-0">
              <input
                type="text"
                value=""
                name="LNAME"
                class="form-control col-6"
                :placeholder="$t('furniture.surname')"
                id="mce-LNAME"
              />
            </div>
            <div class="col-12 mt-md-3 mt-0">
              <input
                type="email"
                value=""
                name="EMAIL"
                placeholder="E-mail"
                class="required email form-control col-12"
                id="mce-EMAIL"
                required
              />
            </div>

            <div id="mce-responses" class="clear foot">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_3738a6018b210b17367403c18_e5cb3d003e"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="col-12 d-flex justify-content-center text-center">
              <input
                v-show="!formSent"
                @click="formSent = true"
                type="submit"
                :value="$t('furniture.subscribe')"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button text-center mt-5"
              />
            </div>
          </form>

          <transition name="message">
            <div v-if="formSent" class="row">
              <div
                class="
                  col-12
                  mb-5
                  pt-md-5
                  pb-md-2 pb-0
                  d-flex
                  justify-content-center
                "
              >
                <div class="message">Thank you!</div>
              </div>

              <div
                class="col-12 mb-5 pb-md-5 pb-0 d-flex justify-content-center"
              >
                <div v-if="formSent" class="button text-center mt-5">
                  {{ $t("furniture.subscribed") }}
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- Caption -->
        <div class="col-12 col-md-5 mb-4 sign-up-text">
          {{ $t("newsletter.caption") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formSent: false,
    };
  },
  methods: {
    submitForm() {
      this.formSent = true;
    },
  },
};
</script>
<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 1rem;
  border-bottom: 1px solid white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 0px rgba(197, 189, 189, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
}

.message {
  width: 100%;
  border-bottom: 1px solid white;
  text-align: center;
}
.form-control {
  color: white;
}
form {
  font-size: 1rem;
  font-weight: normal;
}
.submit-button {
  text-align: center;
  text-transform: uppercase;
}
.button {
  font-size: 0.9rem !important;
}
.button,
input[type="submit"],
input[type="reset"] {
  font-weight: normal;
  border-bottom: 1px solid white;
  width: 100%;
  text-transform: uppercase;
  background: none;
  color: inherit;
  border-left: 0;
  border-right: 0;
  border-top: 0;

  padding: 0.2rem 0 0.18rem 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
textarea,
input {
  padding: 0.2rem 0 0.18rem 0;
  font-weight: 400 !important;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  padding-left: 0;
}
input::placeholder {
  color: #fff;
}
input:focus,
select:focus,
textarea:focus,
.button:focus {
  background: none;
  outline: none;
  color: white;
}
input:focus::placeholder {
  color: transparent;
}
.form-control:focus {
  border-color: white;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sign-up-text {
  text-align: center;
  // font-size:0.7rem;
}

@media (min-width: 768px) {
  // .sign-up-text {
  //   font-size:
  // }
  .newsletter {
    min-height: 100%;
  }
  .button {
    width: 8rem !important;
  }
  .message {
    width: 10rem;
  }
  .title {
    font-size: 2.8rem !important;
    line-height: 2.8rem !important;

    text-align: left !important;
  }
  .title span {
    margin-left: 1.5rem !important;
    text-align: left;
  }
}
.title {
  text-transform: uppercase;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0;
  text-align: center;
}
.title span {
  margin-left: 0;
  text-align: center;
}

.newsletter-wrapper {
  min-height: 70vh;
}

.newsletter {
  display: flex;
  background: grey;
  min-height: 100%;
  background-image: url("~@/assets/img/backgrounds/om_canvas_compressed.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.message-enter-from,
.message-leave-to {
  opacity: 0;
}
.message-enter-active {
  transition: all 150ms ease-out;
  transition-delay: 500ms;
}
.message-leave-active {
  transition: all 100ms ease-in;
}
.message-enter-to,
.message-leave-from {
  opacity: 1;
}
</style>