<template>
  <NavBar />
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component" :key="$route.path"></component>
    </transition>
  </router-view>
  <NewsletterSlide />
  <FooterSlide />
</template>
<script>
import NavBar from "@/components/ui/NavBar";
import NewsletterSlide from "@/components/ui/NewsletterSlide";
import FooterSlide from "@/components/ui/FooterSlide";

export default {
  components: { NavBar, NewsletterSlide, FooterSlide },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Monument Grotesk Mono";
  src: url("~@/assets/fonts/MonumentGrotesk-Mono.woff2") format("woff2"),
    url("~@/assets/fonts/MonumentGrotesk-Mono.woff") format("woff");
}

@font-face {
  font-family: "Monument Grotesk";
  src: url("~@/assets/fonts/ABCMonumentGrotesk-Medium.woff2") format("woff2"),
    url("~@/assets/fonts/ABCMonumentGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: "Monument Grotesk";
  font-style: italic;
  src: url("~@/assets/fonts/ABCMonumentGrotesk-MediumItalic.woff2")
      format("woff2"),
    url("~@/assets/fonts/ABCMonumentGrotesk-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "GT Super Text";
  src: url("~@/assets/fonts/GT-Super-Text-Book.woff2") format("woff2"),
    url("~@/assets/fonts/GT-Super-Text-Book.woff") format("woff");
}
:root {
  --plyr-color-main: rgba(90, 73, 73, 0);
  --plyr-badge-background: rgba(255, 255, 255, 0);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  height: 100%;
  width: 100%;
  font-family: "Monument Grotesk", Helvetica, Arial, sans-serif;
}
body {
  background-color: #473f44 !important;
  height: 100%;
  width: 100%;
}
.underline {
  text-decoration: underline;
}
a.banner-text-link {
  color: white !important;
  text-decoration: underline;
}
a.banner-text-link-dark {
  color: #003456 !important;
  text-decoration: underline;
}
// @media (max-width: 768px) {
//   .credit-light {
//     color: #473f44;
//     opacity: 0.4;
//     font-size: 12px;
//     line-height: 1.1rem;
//     margin-top: 2rem;
//   }
// }

@media (max-width: 576px) {
}
.route-enter-from,
.route-leave-to {
  opacity: 0;
}
.route-enter-active {
  transition: all 150ms ease-out;
}
.route-leave-active {
  transition: all 100ms ease-in;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
}

.custom-pagination {
  height: 3rem;
  font-family: "Monument Grotesk Mono", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
</style>
