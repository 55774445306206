<template>
  <div
    :style="{
      'background-image': 'url(' + backgroundImage + ')',
      color: textColor,
    }"
    class="title-slide"
  >
    <div
      class="
        container-fluid
        d-flex
        justify-content-center
        align-items-center
        slide-wrapper
        flex-column
      "
    >
      <div class="col-12 text-wrapper">
        <h1 class="first">{{ firstLine }}</h1>
        <h1 :class="[thirdLine ? 'my-5' : 'mt-5']" class="second my-md-0">
          {{ secondLine }}
        </h1>
        <h1 v-if="thirdLine" class="third mb-5 mb-md-0">{{ thirdLine }}</h1>
        <div v-if="fourthLine" class="mt-1">
          <img class="img-fluid fourth" :src="fourthLine" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "textColor",
    "backgroundImage",
    "firstLine",
    "secondLine",
    "thirdLine",
    "fourthLine",
  ],
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.first,
.second,
.third {
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .first,
  .second {
    line-height: 2.8rem;
    font-size: 2.8rem;
  }

  .third {
    text-transform: none;
    font-size: 2.3rem;
    line-height: 2.6rem;
  }

  .fourth {
    width: 18rem !important;
  }
}

h1 {
  margin: 0;
}
.first {
  text-transform: uppercase;
}
.second {
  font-family: "GT Super Text", "Times New Roman", Times, serif;
}

.fourth {
  width: 13rem;
}

.text-wrapper {
  text-align: center;
}
.slide-wrapper {
  height: 100%;
}
.title-slide {
  width: 100%;
  height: 95vh;
  background: #5486a7;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>