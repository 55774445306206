<template>
  <footer id="footer">
    <div
      class="
        container
        footer-wrapper
        row
        d-flex
        align-content-end
        justify-content-between
        gy-3
      "
    >
      <!-- Title -->
      <div class="col-12 mt-5 title mb-5 pb-5 mb-md-0 p-0">
        INFO
        <br /><span>&#38; VISIT</span>
      </div>
      <!-- Information -->
      <div class="col-12 information mb-5 mt-4 mb-md-0 px-0">
        {{ $t("visitors.title") }} <br />
        {{ $t("visitors.date") }} <br />
        <br />
      </div>
      <div class="col-12 col-md-5 information px-0">
        <h6 class="small-title">{{ $t("furniture.address") }}</h6>
        <p class="p-0 m-0 address-text" v-html="$t('visitors.address')"></p>
        <span
          ><a target="_blank" href="https://goo.gl/maps/jgsnG1JjHgv6cSgS8">
            (map)</a
          ><br
        /></span>

        <br />
        <h6 class="small-title">{{ $t("furniture.hours") }}</h6>
        {{ $t("visitors.hours") }} ‍<br />
        {{ $t("visitors.entry") }}<br />
        {{ $t("visitors.closed") }}
        <br />
        <br />
        <h6 class="small-title">{{ $t("furniture.contact") }}</h6>
        <a href="mailto:info@stormfromparadise.com"
          >info@stormfromparadise.com</a
        >
        <br />
        <br />
      </div>
      <div class="col-12 col-md-5 information mb-5 mb-md-0 px-0">
        <h6 class="small-title">SOCIAL MEDIA</h6>
        Frequencies<br />
        <a target="_blank" href="https://www.frequenciesproject.info/"
          >frequenciesproject.info</a
        ><br />
        <a
          target="_blank"
          href="https://www.instagram.com/frequenciesproject/"
          >@frequenciesproject</a
        >
        <br />
        <br />
        Arepas y Tamales<br />
        <a target="_blank" href="https://www.arepasytamales.com/"
          >arepasytamales.com</a
        ><br />
        <a target="_blank" href="https://www.instagram.com/arepasytamales/"
          >@arepasytamales</a
        >
        <br />
        <br />
        SAVVY Contemporary<br />
        <a target="_blank" href="https://savvy-contemporary.com/"
          >savvy-contemporary.com</a
        ><br />
        <a target="_blank" href="https://www.instagram.com/savvycontemporary/"
          >@savvycontemporary</a
        >
      </div>
      <!-- Logos -->
      <div
        class="
          col-12
          p-0
          mb-4
          d-flex
          justify-content-evenly
          align-items-center
          row
          mt-4
          pt-3
        "
      >
        <div class="col-md-3 col-8 order-md-3 d-flex justify-content-center">
          <img
            class="img-fluid ayt-logo"
            src="@/assets/img/logos/AYT_logo.png"
            alt=""
          />
        </div>

        <div
          class="
            col-md-3 col-8
            order-md-2
            my-3 my-md-0
            d-flex
            justify-content-center
          "
        >
          <img
            class="img-fluid frequencies-logo"
            src="@/assets/img/logos/Frequencies_logo.png"
            alt=""
          />
        </div>
        <div class="col-md-3 col-8 order-md-1 d-flex justify-content-center">
          <img
            class="img-fluid savvy-logo"
            src="@/assets/img/logos/SAVVY_logo.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.address-text {
  display: inline;
}
@media (min-width: 768px) {
  footer {
    // min-height: 100vh !important;
    height: 100%;
    max-height: 100%;
  }
  .title {
    font-size: 2.8rem !important;
    line-height: 2.8rem !important;

    text-align: left !important;
  }
  .title span {
    margin-left: 1.5rem !important;
    text-align: left;
  }
}
.savvy-logo {
  width: 15rem;
}
.frequencies-logo {
  width: 12rem;
}
.ayt-logo {
  width: 8rem;
}
.logo {
  height: 3rem;
}
.title {
  text-transform: uppercase;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0;
  text-align: center;
}
.title span {
  margin-left: 0;
  text-align: center;
}
.small-title {
  margin: 0;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
}
.information {
  line-height: 1.2rem;
}
.information a {
  color: white;
  text-decoration: none;
}

.footer-wrapper {
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
footer {
  min-height: auto;
  height: auto;
}
</style>