<template>
  <div class="events-slide">
    <div
      class="
        container
        events-wrapper
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <div class="col-12">
        <!-- <h1 class="first mb-3 mb-md-0">SAVVY CONTEMPORARY</h1> -->
        <h1 class="second">{{$t('furniture.public')}}</h1>
      </div>
      <div class="events-button">
        <router-link :to="`/${$i18n.locale}/programme`">{{$t('furniture.events')}}</router-link>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@media (min-width: 768px) {
  h1 {
    font-size: 2.8rem !important;
    line-height: 2.8rem !important;
  }
}
h1 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0;
}

.second {
  font-family: "GT Super Text", "Times New Roman", Times, serif;
}

.events-slide {
  height: 30rem;

  background: #5486a7;
}
.events-wrapper {
  height: 100%;
  position: relative;
  text-align: center;
  font-size: 1.2rem;
}
.events-button {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 10rem;
  font-size: 14px;
  padding-bottom: 0.3rem;
  text-transform: uppercase;
  border-bottom: 1px solid white;
}
.events-button a {
  text-decoration: none;
  color: white;
}
</style>