<template>
  <div>

    <TitleSlide textColor="white" :backgroundImage="backgroundUrl" firstLine="Oscar Murillo" secondLine="A STORM IS BLOWING FROM PARADISE" :thirdLine="$t('title.address')" :fourthLine="dateUrl" />
    <!-- <MarqueeBanner /> -->

    <VideoSlide />

    <TextSlide :slideText="$t('text.exhibition')" />

    <EventsSlide />
    <InstallationGallery />
  </div>
</template>

<script>
// import MarqueeBanner from '@/components/sections/MarqueeBanner'
import VideoSlide from '@/components/sections/VideoSlide'
import InstallationGallery from '@/components/sections/InstallationGallery'

export default {
  components: {VideoSlide, InstallationGallery},
 
  name: "HomePage",
  data() {
    return{
      backgroundUrl: require('@/assets/img/backgrounds/om_graphs_a1_compressed.gif'),
      dateUrl: require('@/assets/img/icons/date.svg'),

    }
  }
};
</script>


<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
