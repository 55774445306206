import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/bootstrap.min.css'
import i18n from './i18n'
import { createPrismic } from '@prismicio/vue'
import TitleSlide from '@/components/sections/TitleSlide'
import TextSlide from '@/components/sections/TextSlide'
import EventsSlide from '@/components/sections/EventsSlide'


const prismic = createPrismic({
  endpoint: 'https://a-storm-is-blowing.cdn.prismic.io/api/v2'
})

const app = createApp(App)

app.use(i18n)
app.use(prismic)


app.component('TitleSlide', TitleSlide)

app.component('TextSlide', TextSlide)
app.component('EventsSlide', EventsSlide)
app.use(router)
app.mount('#app')
