<template>
  <div id="installation-gallery" class="installation-gallery">
    <div class="container pt-5 pt-md-5 mb-md-4 h-100">
      <!-- Mobile -->
      <div
        class="
          row
          mt-4
          mb-1
          d-block d-md-none d-flex
          align-content-between
          h-100
          position-relative
        "
      >
        <div class="col-12 mb-5">
          <swiper
            :initialSlide="1"
            :cssMode="false"
            :loop="true"
            :navigation="{
              nextEl: '.next',
              prevEl: '.prev',
            }"
          >
            <swiper-slide v-for="(image, index) in gallery" :key="index">
              <img
                class="img-fluid lazyload"
                :width="image.primary.image.dimensions.width"
                :height="image.primary.image.dimensions.height"
                :data-src="image.primary.image.url"
                alt=""
              />

              <!-- <div class="col-12 my-5 mobile-caption">
                <prismic-rich-text :field="image.primary.text" />
              </div> -->
            </swiper-slide>
          </swiper>
        </div>

        <div class="col-6 prev">Prev</div>
        <div class="col-6 next">Next</div>
      </div>
      <!-- Desktop -->
      <div class="row mt-5 g-5 mb-1 d-none d-md-block">
        <!-- Thumbnails -->
        <div class="col-12 row m-0">
          <div
            @click="openGallery(image, index)"
            v-for="(image, index) in gallery"
            :key="index"
            class="col-12 col-md-6 thumbnail"
            :class="[index % 2 === 0 ? 'pe-4' : 'ps-4']"
          >
            <div
              :class="[!showMore && index > 3 ? 'd-none' : 'd-block']"
              class="col-12 pb-5"
            >
              <img
                class="img-fluid lazyload"
                :data-src="image.primary.image.url"
                alt=""
                :width="image.primary.image.dimensions.width"
                :height="image.primary.image.dimensions.height"
              />
            </div>
            <!-- <div class="col-12 caption">
              <prismic-rich-text :field="image.primary.text" />
            </div> -->
          </div>
          <div
            @click="showMore = !showMore"
            class="col-12 mt-3 text-center d-flex justify-content-center"
          >
            <div class="show-more">
              <span v-if="!showMore">Show More</span>
              <span v-else>Show Less</span>
            </div>
          </div>
        </div>
        <!-- Large Image -->
        <transition name="route">
          <div v-if="galleryOpen" class="modal-wrapper">
            <div
              class="
                container-fluid
                d-flex
                justify-content-center
                align-items-center
                image-outer-wrapper
              "
            >
              <img
                @click="cycleImages"
                class="img-fluid image-wrapper"
                :src="largeImage.primary.image.url"
                alt=""
              />
            </div>

            <div @click="closeGallery" class="close-btn">Close</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import "lazysizes";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

import "swiper/css";
import "swiper/css/navigation";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      largeImage: null,
      galleryOpen: false,
      gallery: [],
      currentIndex: null,
      showMore: false,
    };
  },
  methods: {
    async getData() {
      const data = await this.$prismic.client.getSingle("installation_gallery");

      this.gallery = data.data.body;
    },

    openGallery(image, index) {
      document.body.style.overflow = "hidden";
      this.largeImage = image;
      this.currentIndex = index;
      this.galleryOpen = true;
    },
    closeGallery() {
      document.body.style.overflow = "auto";
      this.galleryOpen = false;
    },
    cycleImages() {
      let length = this.gallery.length;
      if (this.currentIndex === length - 1) {
        this.currentIndex = 0;
        this.largeImage = this.gallery[this.currentIndex];
      } else {
        this.currentIndex++;
        this.largeImage = this.gallery[this.currentIndex];
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.show-more {
  width: 7rem;
  font-size: 14px;
  padding-bottom: 0.3rem;
  text-transform: uppercase;
  border-bottom: 1px solid white;
  margin-bottom: 0.6rem;
}
.show-more:hover {
  cursor: pointer;
}
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
.image-outer-wrapper {
  height: 100%;
}
img:hover {
  cursor: pointer;
}
.caption-modal {
  bottom: 0;
}
.image-wrapper {
  max-height: 90vh;
}
.modal-wrapper {
  z-index: 12;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #003456;
  padding: 0;
  margin: 0;
}
.modal-wrapper:hover {
  cursor: pointer;
}
.close-btn:hover {
  cursor: pointer;
}
.close-btn {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 2rem;
  padding-bottom: 1rem;
}
.mobile-caption {
  text-align: center;
  font-size: 10px;
}
.next:hover,
.prev:hover {
  cursor: pointer;
}
.next,
.prev {
  user-select: none;
  -webkit-user-select: none;
  font-family: "GT Super Text", "Times New Roman", Times, serif;
  font-size: 14px;
  text-transform: uppercase;
}
.next {
  text-align: right;
}
.prev {
  text-align: left;
}
@media (min-width: 768px) {
  .installation-gallery {
    background: #003456;
    width: 100%;
    // height: 100vh;
    overflow: scroll;
  }
}

.installation-gallery {
  background: #003456;
  width: 100%;
  min-height: 100%;
  overflow: auto;
}
</style>